<template>
    <div>
      <nav-bar></nav-bar>
      <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">
            <div class="row align-items-center">
              <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
                  <div class="page-header">
                      <h4 class="page-title">Medicine List</h4>
                  </div>
              </div>
              <div class="col-lg-5 ms-auto">
                  <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                      <router-link to="/add-medicines" type="button" class="btn btn-gradient-primary btn-fw">Add Medicine</router-link>
                  </div>
              </div>
            </div>
          </br>

          <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body nw-opd">
                    <div class="row">
                        <div class="col-lg-4 mb-lg-0 mb-2">
                            <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchmedicine" id="searchmedicine" autocomplete="off" v-on:keyup="getMedicineList(1)">
                        </div>
                        <div class="col-lg-6 mb-lg-0 mb-2"></div>
                        <div class="col-lg-2 mb-lg-0 mb-2">
                            <multiselect
                                v-model="filter_status"
                                :options="status_list"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="true" placeholder=""
                                :preselect-first="true"
                                :showLabels="false"
                                :allowEmpty="false"
                                @input=getMedicineList(1)
                            /></multiselect>
                        </div>
                    </div>
                  <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Medicine Name</th>
                            <th>Quantity</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(med,i) in medicineLists" :key="i">
                            <td>{{med.medicine_id}}</td>
                            <td>{{med.medicine_name}}</td>
                            <td>{{med.medicine_quantity}}</td>
                            <td><label v-bind:class="{'badge badge-success': med.medicine_status === 'Active','badge badge-danger': med.medicine_status === 'InActive' || med.medicine_status === 'Inactive'}">{{med.medicine_status}}</label></td>
                            <td><router-link :to="{name:'addmedicines',params:{'id':med.medicine_id}}">
                            <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button></router-link> &nbsp;&nbsp;&nbsp;&nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                  <div v-if="is_API_call_running" class="text-center mt-5">
                    <b-spinner label=""></b-spinner>
                  </div>
                  <div class="row" v-if="!is_API_call_running && medicineLists.length == 0" >
                     
                      <div class="col-lg-12 text-center">
                        <br/><br/><br/>
                        <small class="text-muted">{{ errorMessage }}</small>
                        <br/><br/><br/>
                      </div>
                  </div>
                  <div class="b-pagination-outer" v-if="listPages > 1">
                    <ul id="border-pagination">
                      <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                      <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getMedicineList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                      <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    <Footer/>
      </div>
    </div>
</div>
</template>
    <script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../constant/strings.js"

export default {
      name:'OPDS',
      title: string.PAGE_TITLE_MEDICINE,
      components: {
        NavBar,
        Footer,
        SideBar,
        Multiselect
      },
      data() {
        return {
            medicineLists:[],
            is_API_call_running : false,
            listPages:0,
            activePage:1,
            searchtext:'',
            myTimer:null,
            errorMessage : 'No Data Found.',
            status_list:['All','Active', 'Inactive'],
            filter_status: "All",
        }
      },
      mounted() {
        this.getMedicineList(this.activePage);
      },
      methods:{
        ...mapActions("hospital",["getMedicineListData"]),
    
        getMedicineList(page){
          this.activePage = page;
          clearTimeout(this.myTimer)
          this.myTimer = setTimeout(() => {
            this.is_API_call_running = true;
              this.medicineLists = [];
              var bodyFormData = new FormData();
              bodyFormData.append('filter_value', this.searchtext);
              bodyFormData.append('page', page);
              bodyFormData.append('total_record', 50);
              bodyFormData.append('filter_status', this.filter_status);
              this.getMedicineListData(bodyFormData).then((response) => {
                this.is_API_call_running = false;
                if (response.response_code == 200) {
                  this.medicineLists = response.data;
                  this.listPages = response.no_of_pages;
                }else{
                  this.errorMessage = response.message;
                }
              });
          }, 500)
        },

            previewClick(){
              this.activePage--;
              if(this.activePage >= 1){
                this.getMedicineList(this.activePage);
              }else{
                this.activePage = 1;
              }
            },

            nextClick(){
              this.activePage++;
              if(this.activePage <= this.listPages){
                this.getMedicineList(this.activePage);
              }else{
                this.activePage = this.listPages;
              }
            },
      }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
